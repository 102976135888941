.form-container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.form-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.form-container .form-group {
  margin-bottom: 20px;
}

.form-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-container input[type="file"] {
  padding: 5px;
}

.form-container button[type="submit"] {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.form-container button[type="submit"]:hover {
  background-color: #45a049;
}

/* Additional styling for the Stripe Connect setup message */
.stripe-connect-message {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.stripe-connect-message p {
  margin-bottom: 20px; /* Increased spacing */
  font-size: 18px; /* Larger text */
}

.stripe-connect-message button {
    display: inline-block;
    margin-top:10px; /* Space above the button */
    margin-bottom:30px; /* Increased margin to add space below the button */
    padding:10px; 
    background-color:#635bff; /* Stripe purple */
    color:#fff; 
    border:none; 
    border-radius:4px; 
    font-size:18px; /* Larger text */
    cursor:pointer; 
}

.stripe-connect-message button:hover {
    background-color:#5145cd; /* Slightly darker purple for hover */
}

.stripe-connect-message .important-note {
    color:#e53935; 
    font-weight:bold; 
    margin-top:15px; 
    margin-bottom:15px; 
}

.stripe-connect-message .phone-number-note {
    color:#e53935; 
    font-weight:bold; 
}

.important-message {
    margin-top:20px; 
    padding:15px; 
    background-color:#f8f8f8; 
    border:1px solid #ddd; 
    border-radius:4px; 
    text-align:center; 
    font-size:14px; 
    line-height:1.5; 
}

.important-message strong {
    color:#e53935; /* A shade of red to draw attention */
}

/* New styles for the instructions link */
.instructions-link {
    display:inline-block;
    margin-top:30px; /* Increased space above the link */
    margin-bottom:10px; /* Decreased space below the link */
    padding:10px 15px; 
    background-color:#007bff; 
    color:#fff; 
    text-decoration:none; 
    border-radius:4px; 
    font-size:16px; 
    transition:.3s ease; 
}

.instructions-link:hover {
    background-color:#0056b3; 
}

.form-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
}

.form-container .char-count {
  display: block;
  text-align: right;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}