.product-submit-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .product-submit-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .product-submit-form input[type="text"],
  .product-submit-form input[type="email"],
  .product-submit-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .product-submit-form textarea {
    height: 100px;
    resize: vertical;
  }
  
  .product-submit-form input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .product-submit-form input[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .product-submit-form h1 {
    font-size: 18px;
    margin-top: 20px;
    color: #333;
  }
  
  .product-submit-form h2 {
    font-size: 16px;
    color: #666;
  }