.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .privacy-policy h1 {
    color: #2c3e50;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .privacy-policy h2 {
    color: #3498db;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .privacy-policy li {
    margin-bottom: 5px;
  }
  
  .privacy-policy footer {
    margin-top: 40px;
    text-align: center;
    font-style: italic;
    color: #7f8c8d;
  }