.login {
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__logo {
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
}

.login__container {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.login__container > h1 {
    font-weight: 500;
    margin-bottom: 20px;
}

.login__container > form > h5 {
    margin-bottom: 5px;
}

.login__container > form > input,
.login__resetPasswordContainer > input {
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
    width: 98%;
}

.login__container > p {
    margin-top: 15px;
    font-size: 12px;
}

.login__signInButton,
.login__registerButton,
.login__forgotPasswordButton,
.login__resetPasswordButton,
.login__cancelResetButton {
    background-color: limegreen;
    color: white;
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login__registerButton {
    background-color: #f0f0f0;
    color: #333;
}

.login__forgotPasswordButton {
    background-color: #f0f0f0;
    color: #333;
    margin-top: 30px;
}

.login__cancelResetButton {
    background-color: #f0f0f0;
    color: #333;
}

.login__signInButton:hover,
.login__resetPasswordButton:hover {
    background-color: #4caf50;
}

.login__registerButton:hover,
.login__forgotPasswordButton:hover,
.login__cancelResetButton:hover {
    background-color: #e0e0e0;
}

.login__resetPasswordContainer {
    margin-top: 30px;
}

.login__resetPasswordContainer > h5 {
    margin-bottom: 5px;
}

.login__rememberMe {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.login__rememberMe input {
    margin-right: 5px;
}

.login__logoText {
    color: #4CAF50; /* Darker shade of green */
    font-weight: bold;
    font-size: 24px; /* Increase font size */
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
}

.login__slogan {
    color: #007BFF;
    font-size: 16px;
    font-style: italic;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
}