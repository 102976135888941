.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: white;
    border: 1px solid #dddddd;
    border-radius: 3px;
}

.subtotal__gift {
    display: flex;
    align-items: center;
}

.subtotal__gift > input {
    margin-right: 5px;
}

.subtotal > button {
    background: lime;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    color: #111;
}