.checkout {
    display: flex;
    padding: 20px;
    background-color: white;
    height: max-content;
    justify-content: space-between; 
}

.checkout__left {
    flex: 0.8; 
    background-color: white;
}

.checkout__right {
    flex: 0.2; 
    margin-left: 20px; 
    background-color: white;
}

.checkout__ad {
    width: 100%;
    margin-bottom: 10px;
    background-color: white;
}

.checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid white;
}