.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  min-width: 100px;
  background-color: white;
  z-index: 1;
  position: relative; /* Added to position the delete icon */
}

.product__rating {
  display: flex;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product > button {
  background: lime;
  border: 1px solid;
  margin-top: 10px;
  color: #111;
}

.product__price {
  margin-top: 5px;
}

.product__info {
  height: 100px;
  margin-bottom: 15px;
}

.product__deleteIcon {
  position: absolute;
  top: 10px;
  left: 10px; /* Changed from right to left */
  cursor: pointer;
  font-size: 20px;
  z-index: 10; /* Ensure it's above other elements */
}

.product__confirmDelete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 100;
  text-align: center;
}

.product__confirmDelete button {
  margin: 0 10px;
  padding: 5px 10px;
}

.product__description {
  font-size: 1rem; 
  color: #000;
  margin-top: 5px;
  margin-bottom: 10px;
}