.how-it-works {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .how-it-works h1 {
    color: #007BFF;
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5em;
  }
  
  .card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card h2 {
    color: #4CAF50;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.8em;
  }
  
  .card h3 {
    color: #007BFF;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.4em;
  }
  
  .card ul, .card ol {
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .card li {
    margin-bottom: 8px;
  }
  
  .card a {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
  }
  
  .card a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .how-it-works {
      padding: 10px;
    }
  
    .card {
      padding: 15px;
    }
  
    .how-it-works h1 {
      font-size: 2em;
    }
  
    .card h2 {
      font-size: 1.5em;
    }
  
    .card h3 {
      font-size: 1.2em;
    }
  }