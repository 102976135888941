.header {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #5cb85c;;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  .header__logo {
    height: 70px;
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
    margin-top: 18px;
  }
  
  
  .header__optionLineOne {
    font-size: 10px;
  }
  
  .header__optionLineTwo {
    font-size: 13px;
    font-weight: 800;
  }
  
  .header__optionBasket {
    display: flex;
    align-items: center;
    color: white;
  }
  
  .header__basketCount {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .header__nav {
    display: flex;
    justify-content: space-evenly;
  }
  
  .header__option {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
  }

.contact__us {
  display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.header__orders {
  display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.header__chats {
  display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.header__closed__orders {
  display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.header__sold__orders {
  display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.header__contact {
  display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

 .header__howitworks {
  display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}