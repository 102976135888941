.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.home__search {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home__search input {
  width: 500px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.home__container {
  width: 100%;
}

.home__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 1;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  min-height: 400px;
  box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.75);
  transition: transform 0.1s ease-in;
}

.product:hover {
  transform: scale(1.05);
}

.product__info {
  height: 100px;
  margin-bottom: 15px;
}

.product__price {
  margin-top: 5px;
}

.product__rating {
  display: flex;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.chat-prompt {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.chat-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.chat-link:hover {
  text-decoration: underline;
}