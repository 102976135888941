.terms-of-service {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .terms-of-service h1 {
    color: #34495e;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .terms-of-service h2 {
    color: #2980b9;
    margin-top: 30px;
  }
  
  .terms-of-service p {
    margin-bottom: 15px;
  }
  
  .terms-of-service ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .terms-of-service footer {
    margin-top: 40px;
    text-align: center;
    font-style: italic;
  }