.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkoutProduct__info {
    padding-left: 20px;
}

.checkoutProduct__info > button {
    background: lightcoral;
    border: 1px solid;
    margin-top: 10px;
    color: #111;
}

.checkoutProduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
}

.checkoutProduct__rating {
    display: flex;
}

.checkoutProduct__title {
    font-size: 17px;
    font-weight: 800;
}